var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list && _vm.list.length > 0
    ? _c(
        "ul",
        _vm._l(_vm.list, function(msg) {
          return _c("li", { key: msg.uid }, [
            _c("div", { staticClass: "file" }, [
              _c(
                "a",
                {
                  ref: "ide-link-" + msg.uid,
                  refInFor: true,
                  staticClass: "ide-file-link",
                  attrs: {
                    target: "_blank",
                    "data-devtool": "",
                    href: msg.ide
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.openIDE(msg.ide, "ide-link-" + msg.uid)
                    }
                  }
                },
                [
                  _c("strong", [_vm._v("FILE:")]),
                  _vm._v("  " + _vm._s(msg.fileName)),
                  _c("span", [_vm._v(":" + _vm._s(msg.lineNumber))])
                ]
              )
            ]),
            _vm._v(" "),
            typeof msg.message !== "object"
              ? _c("div", { staticClass: "message string" }, [
                  _vm._v("\n            " + _vm._s(msg.message) + "\n        ")
                ])
              : _c(
                  "div",
                  { staticClass: "message tree" },
                  [
                    _c("tree-view", {
                      staticClass: "outputTree",
                      attrs: {
                        data: msg.message,
                        options: {
                          rootObjectKey: "message",
                          link: true,
                          maxDepth: 1
                        }
                      }
                    })
                  ],
                  1
                ),
            _vm._v(" "),
            _c("div", { staticClass: "stack-trace" }, [
              _c(
                "button",
                {
                  attrs: { "data-devtool": "" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.showTrace("trace-list-" + msg.uid)
                    }
                  }
                },
                [_vm._v("\n                Stack Trace\n            ")]
              ),
              _vm._v(" "),
              _c(
                "ul",
                {
                  ref: "trace-list-" + msg.uid,
                  refInFor: true,
                  staticClass: "hidden-trace"
                },
                _vm._l(msg.stack, function(trace, index) {
                  return _c("li", { key: index }, [
                    _c("span", [_vm._v(_vm._s(index + 1) + ".")]),
                    _vm._v(" " + _vm._s(trace) + "\n                ")
                  ])
                }),
                0
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "divider-line" })
          ])
        }),
        0
      )
    : _c("span", { staticClass: "no-results" }, [_vm._v("No Messages")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }