var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "rvw_dev_tools" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.drawerOpen && _vm.mounted && _vm.debugData
          ? _c(
              "div",
              { staticClass: "devtool-drawer" },
              [
                _c(
                  "button",
                  {
                    staticClass: "devtool-close-drawer",
                    attrs: { "data-devtool": "" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.closeDrawer()
                      }
                    }
                  },
                  [
                    _c("svg", { attrs: { role: "img" } }, [
                      _c("use", { attrs: { href: "#devtool-close" } })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "devtool-menu" }, [
                  _c("ul", [
                    _c("li", [
                      _c(
                        "button",
                        {
                          class: { active: _vm.section === "basket" },
                          attrs: { "data-devtool": "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openDrawer("basket")
                            }
                          }
                        },
                        [
                          _c("svg", { attrs: { role: "img" } }, [
                            _c("use", { attrs: { href: "#devtool-basket" } })
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Basket")]),
                          _vm._v(" "),
                          _vm.basketCount > 0
                            ? _c("span", { staticClass: "count" }, [
                                _vm._v(_vm._s(_vm.basketCount))
                              ])
                            : _vm._e()
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          class: { active: _vm.section === "customer" },
                          attrs: { "data-devtool": "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openDrawer("customer")
                            }
                          }
                        },
                        [
                          _c("svg", { attrs: { role: "img" } }, [
                            _c("use", { attrs: { href: "#devtool-customer" } })
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Customer")])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          class: { active: _vm.section === "geolocation" },
                          attrs: { "data-devtool": "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openDrawer("geolocation")
                            }
                          }
                        },
                        [
                          _c("svg", { attrs: { role: "img" } }, [
                            _c("use", {
                              attrs: { href: "#devtool-geolocation" }
                            })
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Geolocation")])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          class: { active: _vm.section === "messages" },
                          attrs: { "data-devtool": "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.openDrawer(
                                "messages",
                                _vm.messageClass.replace("notice-", "")
                              )
                            }
                          }
                        },
                        [
                          _c("svg", { attrs: { role: "img" } }, [
                            _c("use", { attrs: { href: "#devtool-messages" } })
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Messages")]),
                          _vm._v(" "),
                          _vm.messageCount.total > 0
                            ? _c(
                                "span",
                                {
                                  staticClass: "count",
                                  class: _vm.messageClass
                                },
                                [_vm._v(_vm._s(_vm.messageCount.total))]
                              )
                            : _vm._e()
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          class: { active: _vm.section === "preferences" },
                          attrs: { "data-devtool": "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openDrawer("preferences")
                            }
                          }
                        },
                        [
                          _c("svg", { attrs: { role: "img" } }, [
                            _c("use", {
                              attrs: { href: "#devtool-preferences" }
                            })
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Preferences")])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          class: { active: _vm.section === "session" },
                          attrs: { "data-devtool": "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openDrawer("session")
                            }
                          }
                        },
                        [
                          _c("svg", { attrs: { role: "img" } }, [
                            _c("use", { attrs: { href: "#devtool-session" } })
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Session")])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          class: { active: _vm.section === "site" },
                          attrs: { "data-devtool": "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.openDrawer("site")
                            }
                          }
                        },
                        [
                          _c("svg", { attrs: { role: "img" } }, [
                            _c("use", { attrs: { href: "#devtool-site" } })
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v("Site")])
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section === "basket"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "devtool-drawer-section section-basket tree-view"
                        },
                        [
                          _vm.debugData.basket
                            ? _c("tree-view", {
                                staticClass: "outputTree",
                                attrs: {
                                  data: _vm.sortObjectByKeys(
                                    _vm.debugData.basket
                                  ),
                                  options: {
                                    rootObjectKey: "basket",
                                    link: true,
                                    maxDepth: 1
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.debugData.basket
                            ? _c("span", { staticClass: "no-results" }, [
                                _vm._v("Basket is Empty")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section === "customer"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "devtool-drawer-section section-customer tree-view"
                        },
                        [
                          _vm.debugData.session.customer
                            ? _c("tree-view", {
                                staticClass: "outputTree",
                                attrs: {
                                  data: _vm.sortObjectByKeys(
                                    _vm.debugData.session.customer
                                  ),
                                  options: {
                                    rootObjectKey: "customer",
                                    link: true,
                                    maxDepth: 1
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.debugData.session.customer
                            ? _c("span", { staticClass: "no-results" }, [
                                _vm._v("No Customer")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section === "geolocation"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "devtool-drawer-section section-geolocation tree-view"
                        },
                        [
                          _vm.debugData.geolocation
                            ? _c("tree-view", {
                                staticClass: "outputTree",
                                attrs: {
                                  data: _vm.sortObjectByKeys(
                                    _vm.debugData.geolocation
                                  ),
                                  options: {
                                    rootObjectKey: "geolocation",
                                    link: true,
                                    maxDepth: 1
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.debugData.geolocation
                            ? _c("span", { staticClass: "no-results" }, [
                                _vm._v("No Geolocation")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section === "messages"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "devtool-drawer-section section-messages log-view"
                        },
                        [
                          _c("div", { staticClass: "button-wrapper" }, [
                            _c(
                              "button",
                              {
                                staticClass: "notice-debug",
                                class: {
                                  empty: _vm.messageCount.debug === 0,
                                  active: _vm.subsection === "debug"
                                },
                                attrs: { "data-devtool": "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openDrawer("messages", "debug")
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Debug")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "count notice-debug" },
                                  [_vm._v(_vm._s(_vm.messageCount.debug))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "notice-error",
                                class: {
                                  empty: _vm.messageCount.error === 0,
                                  active: _vm.subsection === "error"
                                },
                                attrs: { "data-devtool": "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openDrawer("messages", "error")
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Error")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "count notice-error" },
                                  [_vm._v(_vm._s(_vm.messageCount.error))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "notice-fatal",
                                class: {
                                  empty: _vm.messageCount.fatal === 0,
                                  active: _vm.subsection === "fatal"
                                },
                                attrs: { "data-devtool": "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openDrawer("messages", "fatal")
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Fatal")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "count notice-fatal" },
                                  [_vm._v(_vm._s(_vm.messageCount.fatal))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "notice-info",
                                class: {
                                  empty: _vm.messageCount.info === 0,
                                  active: _vm.subsection === "info"
                                },
                                attrs: { "data-devtool": "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openDrawer("messages", "info")
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Info")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "count notice-info" },
                                  [_vm._v(_vm._s(_vm.messageCount.info))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "notice-log",
                                class: {
                                  empty: _vm.messageCount.log === 0,
                                  active: _vm.subsection === "log"
                                },
                                attrs: { "data-devtool": "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openDrawer("messages", "log")
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Log")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "count notice-log" },
                                  [_vm._v(_vm._s(_vm.messageCount.log))]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "notice-warn",
                                class: {
                                  empty: _vm.messageCount.warn === 0,
                                  active: _vm.subsection === "warn"
                                },
                                attrs: { "data-devtool": "" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openDrawer("messages", "warn")
                                  }
                                }
                              },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Warn")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "count notice-warn" },
                                  [_vm._v(_vm._s(_vm.messageCount.warn))]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm.subsection === "debug" &&
                          _vm.debugData.messages &&
                          typeof _vm.debugData.messages.debug !== "undefined"
                            ? _c(
                                "div",
                                { staticClass: "subsection" },
                                [
                                  _c("messages", {
                                    attrs: {
                                      list: _vm.debugData.messages.debug
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.subsection === "error" &&
                          _vm.debugData.messages &&
                          typeof _vm.debugData.messages.error !== "undefined"
                            ? _c(
                                "div",
                                { staticClass: "subsection" },
                                [
                                  _c("messages", {
                                    attrs: {
                                      list: _vm.debugData.messages.error
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.subsection === "fatal" &&
                          _vm.debugData.messages &&
                          typeof _vm.debugData.messages.fatal !== "undefined"
                            ? _c(
                                "div",
                                { staticClass: "subsection" },
                                [
                                  _c("messages", {
                                    attrs: {
                                      list: _vm.debugData.messages.fatal
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.subsection === "info" &&
                          _vm.debugData.messages &&
                          typeof _vm.debugData.messages.info !== "undefined"
                            ? _c(
                                "div",
                                { staticClass: "subsection" },
                                [
                                  _c("messages", {
                                    attrs: { list: _vm.debugData.messages.info }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.subsection === "log" &&
                          _vm.debugData.messages &&
                          typeof _vm.debugData.messages.log !== "undefined"
                            ? _c(
                                "div",
                                { staticClass: "subsection" },
                                [
                                  _c("messages", {
                                    attrs: { list: _vm.debugData.messages.log }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.subsection === "warn" &&
                          _vm.debugData.messages &&
                          typeof _vm.debugData.messages.warn !== "undefined"
                            ? _c(
                                "div",
                                { staticClass: "subsection" },
                                [
                                  _c("messages", {
                                    attrs: { list: _vm.debugData.messages.warn }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section === "preferences"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "devtool-drawer-section section-preferences tree-view"
                        },
                        [
                          _vm.debugData.preferences
                            ? _c("tree-view", {
                                staticClass: "outputTree",
                                attrs: {
                                  data: _vm.sortObjectByKeys(
                                    _vm.debugData.preferences
                                  ),
                                  options: {
                                    rootObjectKey: "preferences",
                                    link: true,
                                    maxDepth: 1
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.debugData.preferences
                            ? _c("span", { staticClass: "no-results" }, [
                                _vm._v("No Preferences")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section === "session"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "devtool-drawer-section section-session tree-view"
                        },
                        [
                          _vm.debugData.session
                            ? _c("tree-view", {
                                staticClass: "outputTree",
                                attrs: {
                                  data: _vm.sortObjectByKeys(
                                    _vm.debugData.session
                                  ),
                                  options: {
                                    rootObjectKey: "session",
                                    link: true,
                                    maxDepth: 1
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.debugData.session
                            ? _c("span", { staticClass: "no-results" }, [
                                _vm._v("No Session")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.section === "site"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "devtool-drawer-section section-site tree-view"
                        },
                        [
                          _vm.debugData.site
                            ? _c("tree-view", {
                                staticClass: "outputTree",
                                attrs: {
                                  data: _vm.sortObjectByKeys(
                                    _vm.debugData.site
                                  ),
                                  options: {
                                    rootObjectKey: "site",
                                    link: true,
                                    maxDepth: 1,
                                    limitRenderDepth: 2
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.debugData.site
                            ? _c("span", { staticClass: "no-results" }, [
                                _vm._v("No Site")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.toolbarShown && !_vm.drawerOpen && _vm.mounted && _vm.debugData
          ? _c(
              "div",
              {
                staticClass: "devtool-toolbar",
                on: {
                  click: function($event) {
                    if ($event.target !== $event.currentTarget) {
                      return null
                    }
                    return _vm.resetToolbar()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "toolbar-button-wrapper" },
                  [
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.popovers.basket
                        ? _c(
                            "div",
                            {
                              ref: "popoverBasket",
                              staticClass: "devtool-popover basket",
                              attrs: { id: "popoverBasket", role: "tooltip" }
                            },
                            [
                              _c("div", { staticClass: "table" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content:
                                            _vm.basketCount > 0
                                              ? "View Full Basket Details"
                                              : "Basket Empty",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: basketCount > 0 ? 'View Full Basket Details' : 'Basket Empty', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    class: { empty: !_vm.debugData.basket },
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer("basket")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Items")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-default" },
                                      [_vm._v(_vm._s(_vm.basketCount))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content:
                                            _vm.debugData.basket &&
                                            _vm.debugData.basket.shipments &&
                                            _vm.debugData.basket.shipments
                                              .length > 0
                                              ? "View Full Basket Details"
                                              : "Basket Empty",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: debugData.basket && debugData.basket.shipments && debugData.basket.shipments.length > 0 ? 'View Full Basket Details' : 'Basket Empty', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    class: { empty: !_vm.debugData.basket },
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer("basket")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Shipments")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-default" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.debugData.basket &&
                                              _vm.debugData.basket.shipments &&
                                              _vm.debugData.basket.shipments
                                                .length
                                              ? _vm.debugData.basket.shipments
                                                  .length
                                              : 0
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Basket",
                              classes: _vm.popovers.basket
                                ? "devtool-tooltip-disabled"
                                : "devtool-tooltip",
                              delay: { show: _vm.tooltipDelay }
                            },
                            expression:
                              "{ content: 'Basket', classes: popovers.basket ? 'devtool-tooltip-disabled' : 'devtool-tooltip', delay: { show: tooltipDelay } }"
                          }
                        ],
                        ref: "popoverButtonBasket",
                        staticClass: "toolbar-button basket",
                        class: {
                          active: _vm.popovers.basket,
                          "no-count": _vm.basketCount === 0,
                          "notice-good": _vm.basketCount > 0
                        },
                        attrs: {
                          id: "popoverButtonBasket",
                          "aria-describedby": "popoverBasket",
                          "data-devtool": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.togglePopover("basket")
                          }
                        }
                      },
                      [
                        _vm.basketCount > 0
                          ? _c("span", [_vm._v(_vm._s(_vm.basketCount))])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("svg", { attrs: { role: "img" } }, [
                          _c("use", { attrs: { href: "#devtool-basket" } })
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "toolbar-button-wrapper" },
                  [
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.popovers.customer
                        ? _c(
                            "div",
                            {
                              ref: "popoverCustomer",
                              staticClass: "devtool-popover customer",
                              attrs: { id: "popoverCustomer", role: "tooltip" }
                            },
                            [
                              _c("div", { staticClass: "table" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content: "View Full Customer Details",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: 'View Full Customer Details', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer("customer")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Anonymous")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-default" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.debugData.session.customer
                                                .anonymous
                                                ? "✓"
                                                : " "
                                            ) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content: "View Full Customer Details",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: 'View Full Customer Details', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer("customer")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Authenticated")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-default" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.debugData.session.customer
                                                .authenticated
                                                ? "✓"
                                                : " "
                                            ) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content: "View Full Customer Details",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: 'View Full Customer Details', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer("customer")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("External")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-default" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.debugData.session.customer
                                                .externallyAuthenticated
                                                ? "✓"
                                                : " "
                                            ) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content: "View Full Customer Details",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: 'View Full Customer Details', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer("customer")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Registered")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-default" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.debugData.session.customer
                                                .registered
                                                ? "✓"
                                                : " "
                                            ) +
                                            "\n                                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Customer",
                              classes: _vm.popovers.customer
                                ? "devtool-tooltip-disabled"
                                : "devtool-tooltip",
                              delay: { show: _vm.tooltipDelay }
                            },
                            expression:
                              "{ content: 'Customer', classes: popovers.customer ? 'devtool-tooltip-disabled' : 'devtool-tooltip', delay: { show: tooltipDelay } }"
                          }
                        ],
                        ref: "popoverButtonCustomer",
                        staticClass: "toolbar-button customer no-count",
                        class: { active: _vm.popovers.customer },
                        attrs: {
                          id: "popoverButtonCustomer",
                          "aria-describedby": "popoverCustomer",
                          "data-devtool": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.togglePopover("customer")
                          }
                        }
                      },
                      [
                        _c("svg", { attrs: { role: "img" } }, [
                          _c("use", { attrs: { href: "#devtool-customer" } })
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "toolbar-button-wrapper" },
                  [
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.popovers.geolocation
                        ? _c(
                            "div",
                            {
                              ref: "popoverGeolocation",
                              staticClass: "devtool-popover geolocation",
                              attrs: {
                                id: "popoverGeolocation",
                                role: "tooltip"
                              }
                            },
                            [
                              _c("div", { staticClass: "table" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content:
                                            "View Full Geolocation Details",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: 'View Full Geolocation Details', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer("geolocation")
                                      }
                                    }
                                  },
                                  [
                                    _vm.debugData.geolocation.city &&
                                    _vm.debugData.geolocation.regionCode
                                      ? _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.debugData.geolocation.city
                                              ) +
                                              ",\n                                    " +
                                              _vm._s(
                                                _vm.debugData.geolocation
                                                  .regionCode
                                              ) +
                                              "\n                                    " +
                                              _vm._s(
                                                _vm.debugData.geolocation
                                                  .postalCode || ""
                                              ) +
                                              "\n                                "
                                          )
                                        ])
                                      : _vm.debugData.geolocation.city
                                      ? _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.debugData.geolocation.city
                                              ) +
                                              "\n                                "
                                          )
                                        ])
                                      : _vm.debugData.geolocation.postalCode
                                      ? _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.debugData.geolocation
                                                  .postalCode
                                              ) +
                                              "\n                                "
                                          )
                                        ])
                                      : _vm.debugData.geolocation.countryCode
                                      ? _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.debugData.geolocation
                                                  .countryCode
                                              ) +
                                              "\n                                "
                                          )
                                        ])
                                      : _vm.debugData.geolocation.latitude &&
                                        _vm.debugData.geolocation.longitude
                                      ? _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                _vm.debugData.geolocation
                                                  .latitude
                                              ) +
                                              "\n                                    " +
                                              _vm._s(
                                                _vm.debugData.geolocation
                                                  .longitude
                                              ) +
                                              "\n                                "
                                          )
                                        ])
                                      : _c("span", { staticClass: "label" }, [
                                          _vm._v(
                                            "\n                                    No Location Data\n                                "
                                          )
                                        ])
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Geolocation",
                              classes: _vm.popovers.geolocation
                                ? "devtool-tooltip-disabled"
                                : "devtool-tooltip",
                              delay: { show: _vm.tooltipDelay }
                            },
                            expression:
                              "{ content: 'Geolocation', classes: popovers.geolocation ? 'devtool-tooltip-disabled' : 'devtool-tooltip', delay: { show: tooltipDelay } }"
                          }
                        ],
                        ref: "popoverButtonGeolocation",
                        staticClass: "toolbar-button geolocation no-count",
                        class: { active: _vm.popovers.geolocation },
                        attrs: {
                          id: "popoverButtonGeolocation",
                          "aria-describedby": "popoverGeolocation",
                          "data-devtool": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.togglePopover("geolocation")
                          }
                        }
                      },
                      [
                        _c("svg", { attrs: { role: "img" } }, [
                          _c("use", { attrs: { href: "#devtool-geolocation" } })
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "toolbar-button-wrapper" },
                  [
                    _c("transition", { attrs: { name: "fade" } }, [
                      _vm.popovers.messages
                        ? _c(
                            "div",
                            {
                              ref: "popoverMessages",
                              staticClass: "devtool-popover messages",
                              attrs: { id: "popoverMessages", role: "tooltip" }
                            },
                            [
                              _c("div", { staticClass: "table" }, [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content:
                                            _vm.messageCount.debug > 0
                                              ? "View Debug Messages"
                                              : "No Debug Messages",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: messageCount.debug > 0 ? 'View Debug Messages' : 'No Debug Messages', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    class: {
                                      empty: _vm.messageCount.debug === 0
                                    },
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer(
                                          "messages",
                                          "debug"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Debug")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-debug" },
                                      [_vm._v(_vm._s(_vm.messageCount.debug))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content:
                                            _vm.messageCount.error > 0
                                              ? "View Error Messages"
                                              : "No Error Messages",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: messageCount.error > 0 ? 'View Error Messages' : 'No Error Messages', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    class: {
                                      empty: _vm.messageCount.error === 0
                                    },
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer(
                                          "messages",
                                          "error"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Error")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-error" },
                                      [_vm._v(_vm._s(_vm.messageCount.error))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content:
                                            _vm.messageCount.fatal > 0
                                              ? "View Fatal Messages"
                                              : "No Fatal Messages",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: messageCount.fatal > 0 ? 'View Fatal Messages' : 'No Fatal Messages', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    class: {
                                      empty: _vm.messageCount.fatal === 0
                                    },
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer(
                                          "messages",
                                          "fatal"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Fatal")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-fatal" },
                                      [_vm._v(_vm._s(_vm.messageCount.fatal))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content:
                                            _vm.messageCount.info > 0
                                              ? "View Info Messages"
                                              : "No Info Messages",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: messageCount.info > 0 ? 'View Info Messages' : 'No Info Messages', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    class: {
                                      empty: _vm.messageCount.info === 0
                                    },
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer(
                                          "messages",
                                          "info"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Info")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-info" },
                                      [_vm._v(_vm._s(_vm.messageCount.info))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content:
                                            _vm.messageCount.log > 0
                                              ? "View Log Messages"
                                              : "No Log Messages",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: messageCount.log > 0 ? 'View Log Messages' : 'No Log Messages', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    class: {
                                      empty: _vm.messageCount.log === 0
                                    },
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer("messages", "log")
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Log")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-log" },
                                      [_vm._v(_vm._s(_vm.messageCount.log))]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip.right",
                                        value: {
                                          content:
                                            _vm.messageCount.warn > 0
                                              ? "View Warn Messages"
                                              : "No Warn Messages",
                                          classes: "devtool-tooltip",
                                          delay: { show: _vm.tooltipDelay }
                                        },
                                        expression:
                                          "{ content: messageCount.warn > 0 ? 'View Warn Messages' : 'No Warn Messages', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }",
                                        modifiers: { right: true }
                                      }
                                    ],
                                    class: {
                                      empty: _vm.messageCount.warn === 0
                                    },
                                    attrs: { "data-devtool": "" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.openDrawer(
                                          "messages",
                                          "warn"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Warn")
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "count notice-warn" },
                                      [_vm._v(_vm._s(_vm.messageCount.warn))]
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Messages",
                              classes: _vm.popovers.messages
                                ? "devtool-tooltip-disabled"
                                : "devtool-tooltip",
                              delay: { show: _vm.tooltipDelay }
                            },
                            expression:
                              "{ content: 'Messages', classes: popovers.messages ? 'devtool-tooltip-disabled' : 'devtool-tooltip', delay: { show: tooltipDelay } }"
                          }
                        ],
                        ref: "popoverButtonMessages",
                        staticClass: "toolbar-button messages",
                        class: [
                          {
                            active: _vm.popovers.messages,
                            "no-count": _vm.messageCount.total === 0
                          },
                          _vm.messageClass
                        ],
                        attrs: {
                          id: "popoverButtonMessages",
                          "aria-describedby": "popoverMessages",
                          "data-devtool": ""
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.togglePopover("messages")
                          }
                        }
                      },
                      [
                        _vm.messageCount.total > 0
                          ? _c("span", [_vm._v(_vm._s(_vm.messageCount.total))])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("svg", { attrs: { role: "img" } }, [
                          _c("use", { attrs: { href: "#devtool-messages" } })
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "toolbar-button-wrapper" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content: "Preferences",
                            classes: _vm.popovers.preferences
                              ? "devtool-tooltip-disabled"
                              : "devtool-tooltip",
                            delay: { show: _vm.tooltipDelay }
                          },
                          expression:
                            "{ content: 'Preferences', classes: popovers.preferences ? 'devtool-tooltip-disabled' : 'devtool-tooltip', delay: { show: tooltipDelay } }"
                        }
                      ],
                      ref: "popoverButtonPreferences",
                      staticClass: "toolbar-button preferences no-count",
                      class: { active: _vm.popovers.preferences },
                      attrs: {
                        id: "popoverButtonPreferences",
                        "aria-describedby": "popoverPreferences",
                        "data-devtool": ""
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openDrawer("preferences")
                        }
                      }
                    },
                    [
                      _c("svg", { attrs: { role: "img" } }, [
                        _c("use", { attrs: { href: "#devtool-preferences" } })
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "toolbar-button-wrapper" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content: "Session",
                            classes: _vm.popovers.session
                              ? "devtool-tooltip-disabled"
                              : "devtool-tooltip",
                            delay: { show: _vm.tooltipDelay }
                          },
                          expression:
                            "{ content: 'Session', classes: popovers.session ? 'devtool-tooltip-disabled' : 'devtool-tooltip', delay: { show: tooltipDelay } }"
                        }
                      ],
                      ref: "popoverButtonSession",
                      staticClass: "toolbar-button session no-count",
                      class: { active: _vm.popovers.session },
                      attrs: {
                        id: "popoverButtonSession",
                        "aria-describedby": "popoverSession",
                        "data-devtool": ""
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openDrawer("session")
                        }
                      }
                    },
                    [
                      _c("svg", { attrs: { role: "img" } }, [
                        _c("use", { attrs: { href: "#devtool-session" } })
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "toolbar-button-wrapper" }, [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content: "Site",
                            classes: _vm.popovers.site
                              ? "devtool-tooltip-disabled"
                              : "devtool-tooltip",
                            delay: { show: _vm.tooltipDelay }
                          },
                          expression:
                            "{ content: 'Site', classes: popovers.site ? 'devtool-tooltip-disabled' : 'devtool-tooltip', delay: { show: tooltipDelay } }"
                        }
                      ],
                      ref: "popoverButtonSite",
                      staticClass: "toolbar-button site no-count",
                      class: { active: _vm.popovers.site },
                      attrs: {
                        id: "popoverButtonSite",
                        "aria-describedby": "popoverSite",
                        "data-devtool": ""
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openDrawer("site")
                        }
                      }
                    },
                    [
                      _c("svg", { attrs: { role: "img" } }, [
                        _c("use", { attrs: { href: "#devtool-site" } })
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.consoleURL && _vm.consoleURL !== ""
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: {
                              content: "Open SFCC Console",
                              classes: "devtool-tooltip",
                              delay: { show: _vm.tooltipDelay }
                            },
                            expression:
                              "{ content: 'Open SFCC Console', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }"
                          }
                        ],
                        staticClass: "open-console",
                        attrs: {
                          href: _vm.consoleURL,
                          target: "devtool-console",
                          "data-devtool": ""
                        }
                      },
                      [
                        _c("svg", { attrs: { role: "img" } }, [
                          _c("use", { attrs: { href: "#devtool-console" } })
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: "Close SFCC Toolbar",
                          classes: "devtool-tooltip",
                          delay: { show: _vm.tooltipDelay }
                        },
                        expression:
                          "{ content: 'Close SFCC Toolbar', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }"
                      }
                    ],
                    staticClass: "hide-toolbar",
                    attrs: { "data-devtool": "" },
                    on: {
                      click: function($event) {
                        return _vm.closeToolbar()
                      }
                    }
                  },
                  [
                    _c("svg", { attrs: { role: "img" } }, [
                      _c("use", { attrs: { href: "#devtool-close" } })
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.toolbarShown && !_vm.drawerOpen && _vm.mounted
          ? _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: "Open SFCC Toolbar",
                      classes: "devtool-tooltip",
                      delay: { show: _vm.tooltipDelay }
                    },
                    expression:
                      "{ content: 'Open SFCC Toolbar', classes: 'devtool-tooltip', delay: { show: tooltipDelay } }"
                  }
                ],
                staticClass: "devtool-show-toolbar",
                attrs: { "data-devtool": "" },
                on: {
                  click: function($event) {
                    return _vm.openToolbar()
                  }
                }
              },
              [
                _c("svg", { attrs: { role: "img" } }, [
                  _c("use", { attrs: { href: "#devtool-salesforce" } })
                ])
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            id: "rvw-dev-tools-icons"
          }
        },
        [
          _c(
            "symbol",
            { attrs: { id: "devtool-basket", viewBox: "0 0 48.12 47.06" } },
            [
              _c(
                "g",
                {
                  attrs: {
                    fill: "none",
                    stroke: "#FFFFFF",
                    "stroke-miterlimit": "10"
                  }
                },
                [
                  _c("ellipse", {
                    attrs: {
                      cx: "19.41",
                      cy: "41.45",
                      rx: "3.68",
                      ry: "3.78",
                      "stroke-width": "3.65"
                    }
                  }),
                  _vm._v(" "),
                  _c("ellipse", {
                    attrs: {
                      cx: "35.07",
                      cy: "41.45",
                      rx: "3.68",
                      ry: "3.78",
                      "stroke-width": "3.65"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M10 8h35.41c.52 0 .82.07.67.55L40.68 26c-.15.47-.55 1.05-.91 1.05H15.63c-.36 0-.76-.72-.91-1.21L9.32 8.73C9.17 8.25 9.47 8 10 8z",
                      "stroke-width": "4"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-width": "3.65",
                      d:
                        "M9.28 7.86L7.44 3.23M15.73 26.78c-.34 0-.73.39-.88.88L12 35.3"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-width": "4",
                      d: "M2 2h5M12 36h29"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "symbol",
            { attrs: { id: "devtool-close", viewBox: "0 0 33.84 33.67" } },
            [
              _c(
                "g",
                {
                  attrs: {
                    fill: "none",
                    stroke: "#FFFFFF",
                    "stroke-linecap": "round",
                    "stroke-miterlimit": "10",
                    "stroke-width": "4"
                  }
                },
                [
                  _c("path", {
                    attrs: { d: "M2 2l29.84 29.67M31.84 2L2 31.67" }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "symbol",
            { attrs: { id: "devtool-console", viewBox: "0 0 640 512" } },
            [
              _c(
                "g",
                { attrs: { fill: "#FFFFFF", "stroke-linecap": "round" } },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M257.981 272.971L63.638 467.314c-9.373 9.373-24.569 9.373-33.941 0L7.029 444.647c-9.357-9.357-9.375-24.522-.04-33.901L161.011 256 6.99 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L257.981 239.03c9.373 9.372 9.373 24.568 0 33.941zM640 456v-32c0-13.255-10.745-24-24-24H312c-13.255 0-24 10.745-24 24v32c0 13.255 10.745 24 24 24h304c13.255 0 24-10.745 24-24z"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "symbol",
            { attrs: { id: "devtool-customer", viewBox: "0 0 44 46.99" } },
            [
              _c(
                "g",
                {
                  attrs: {
                    fill: "none",
                    stroke: "#FFFFFF",
                    "stroke-linecap": "round",
                    "stroke-miterlimit": "10",
                    "stroke-width": "4"
                  }
                },
                [
                  _c("circle", { attrs: { cx: "22", cy: "13", r: "11" } }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M42 36.74v6.07c0 .58-.2 2.18-.78 2.18H3.28C2.71 45 2 43.39 2 42.81v-6.57c0-6.63 9-12 20-12s20 5.37 20 12z"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "symbol",
            { attrs: { id: "devtool-geolocation", viewBox: "0 0 36 46" } },
            [
              _c(
                "g",
                {
                  attrs: {
                    fill: "none",
                    stroke: "#FFFF",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "4"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M18 2a15.82 15.82 0 0116 15.63A15.34 15.34 0 0130.83 27"
                    }
                  }),
                  _vm._v(" "),
                  _c("circle", { attrs: { cx: "18", cy: "17.63", r: "5" } }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d:
                        "M18 44l12.83-17.03M18 2A15.82 15.82 0 002 17.63 15.34 15.34 0 005.17 27M18 44L5.17 26.97"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "symbol",
            { attrs: { id: "devtool-messages", viewBox: "0 0 46 42.83" } },
            [
              _c(
                "g",
                {
                  attrs: {
                    fill: "none",
                    stroke: "#FFFFFF",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "4"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M15 32.08H3c-.55 0-1-.81-1-1.37V2.34c0-.56.45-.26 1-.26h40c.55 0 1-.3 1 .26v28.37c0 .56-.45 1.37-1 1.37H29M22 40.83l6.63-8.11M22 40.83l-6.62-8.11"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "symbol",
            { attrs: { id: "devtool-preferences", viewBox: "0 0 46 44" } },
            [
              _c(
                "g",
                {
                  attrs: {
                    fill: "none",
                    stroke: "#FFFFFF",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "4"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M2 6h8M10 2h12v8H10zM22 6h22M44 22h-8M36 26H24v-8h12zM24 22H2M2 38h8M10 34h12v8H10zM22 38h22"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "symbol",
            { attrs: { id: "devtool-salesforce", viewBox: "0 0 32 32" } },
            [
              _c(
                "g",
                { attrs: { fill: "#FFFFFF", "stroke-linecap": "round" } },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M10 6c-3.9 0-7 3.1-7 7 0 .4.1.8.1 1.2-.7 1-1.1 2.2-1.1 3.5 0 3.4 2.8 6.2 6.2 6.3 1.3 1.2 2.9 2 4.8 2s3.6-.9 4.9-2.1h.1c1.6 0 2.9-.9 3.8-2.1.4.1.8.2 1.2.2 3.9 0 7-3.1 7-7s-3.1-7-7-7c-.7 0-1.3.2-2 .4-1.1-.9-2.5-1.4-4-1.4-.9 0-1.8.3-2.6.7C13.2 6.7 11.7 6 10 6zm0 2c1.4 0 2.6.5 3.5 1.4l.5.5.6-.3c.8-.4 1.5-.6 2.4-.6 1.2 0 2.3.4 3.2 1.2l.5.4.6-.2c.6-.2 1.2-.3 1.8-.3 2.8 0 5 2.2 5 5s-2.2 5-5 5c-.4 0-.9-.1-1.3-.2l-.8-.2-.4.7c-.5.9-1.4 1.6-2.6 1.6h-.3l-.5-.1-.3.4c-1 1-2.4 1.7-3.9 1.7s-2.8-.7-3.7-1.7l-.4-.3h-.7C5.9 22 4 20.1 4 17.7c0-1 .4-1.9 1-2.7l.3-.4-.1-.5c-.1-.3-.2-.7-.2-1.1 0-2.8 2.2-5 5-5z"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "symbol",
            { attrs: { id: "devtool-session", viewBox: "0 0 41.92 41.92" } },
            [
              _c(
                "g",
                {
                  attrs: {
                    fill: "none",
                    stroke: "#FFFFFF",
                    "stroke-linecap": "round"
                  }
                },
                [
                  _c("circle", {
                    attrs: {
                      cx: "20.96",
                      cy: "20.96",
                      r: "19",
                      "stroke-miterlimit": "10",
                      "stroke-width": "3.92"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      "stroke-linejoin": "round",
                      "stroke-width": "4",
                      d: "M19.96 12.46v9.22l5.34 3.72"
                    }
                  })
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "symbol",
            { attrs: { id: "devtool-site", viewBox: "0 0 40 46" } },
            [
              _c(
                "g",
                {
                  attrs: {
                    fill: "none",
                    stroke: "#FFFFFF",
                    "stroke-linejoin": "round",
                    "stroke-width": "4"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M13 44H2.88a.78.78 0 01-.88-.74V18M38 19v24.26a.78.78 0 01-.88.74H26"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      d: "M38 18.89L20 2 2 18.43"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", { attrs: { d: "M14 46V31h12v15" } })
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }